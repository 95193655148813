//==============================================
// Module/logo
//==============================================

.m-logo {
  display: block;
  text-align: center;

  @include media('>sm') {
    padding: $padding;
    margin-bottom: $margin;
  }

  img {
    margin: 0 auto;
    display: block;
  }

  .mobile {
    @include media('>sm') {
      display: none;
    }
  }

  .desktop {
    display: none;

    @include media('>sm') {
      display: inline-block;
    }
  }
}
