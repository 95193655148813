//==============================================
// Module/hamburger
//==============================================

.m-hamburger {
  @extend [class^="icon-"];
  @extend .icon !optional;
  @extend .icon-menu !optional;
  background-color: transparent;
  border: none;
  color: $color-accent;
  font-size: rem-calc($font-size-large) !important;
  font-weight: bold;

  // Close button
  &-close {
    @extend .m-hamburger;
    @extend .icon-close !optional;
  }
}
