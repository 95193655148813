//==============================================
// Layout/footer
//==============================================

.l-footer {
  height: auto;
  padding: rem-calc(30);
  background-color: $color-accent;

  @include media('>sm') {
    left: 0;
    right: 0;
    bottom: 0;
    height: rem-calc(250);
    position: absolute;
  }

  .line {
    width: 100%;
    padding-top: rem-calc(15);
    border-bottom: 1px solid $white;

    @include media('>sm') {
      padding-top: rem-calc(25);
    }
  }

  .icons {
    margin: 0;
    padding: rem-calc(15) 0 0 0;
    list-style: none;
    text-align: center;

    li {
      margin: 0 rem-calc(10);
      display: inline-block;

       a {
         color: $white;

         i {
          font-size: rem-calc(28);
         }
       }
    }

    @include media('>sm') {
      display: none;
    }
  }

  .nav {
    margin: 0;
    padding: rem-calc(30) 0 0 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
    justify-content: space-between;

    li {
      color: $white;
      margin: 0;
      padding: 0;

      &:last-child {
        padding: rem-calc(50) 0 rem-calc(30) 0;
        flex-grow: 1;
        flex-basis: 100%;

        @include media('>sm') {
          padding: 0;
          flex-grow: 0;
          flex-basis: auto;
        }
      }

      a {
        color: $white;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
