//==============================================
// Module/breadcrumb
//==============================================

.m-breadcrumb {
  @include reset-list;
  border-top: -border($grey, $b-s-base);
  border-bottom: -border($grey, $b-s-base);
  margin-bottom: $margin*1.25;

  @include media('>sm') {
    border-top: 0;
    border-width: $b-s-medium;
    margin-bottom: 0;
    border-bottom-color: $color-primary;
  }

  .breadcrumbs {
    > span {
      display: inline-block;

      .item {
        display: inline-block;
        padding: rem-calc(ceil($padding/3)) rem-calc(ceil($padding/2));
        font-size: rem-calc($font-size-small);
        text-transform: uppercase;

        @include media('>sm') {
          padding: rem-calc(ceil($padding/2)) rem-calc(ceil($padding/2));
          font-size: rem-calc($font-size-nav-header-desktop);
          font-weight: bold;
          margin-bottom: 0;
        }

      }

      &:first-child {
        .item {
          padding-left: 0;

          &:before {
            margin: 0 rem-calc(0) 0 0;
            display: inline-block;
            content: $icon-arrow-right;
            transform: translate(0px, 1px);
            line-height: 1;
            font-family: $icomoon-font-family;
          }
        }
      }
    }

    .icon {
      font-weight: bold;
      vertical-align: middle;
    }
  }
}
