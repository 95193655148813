$icomoon-font-path: "../fonts" !default;
$icomoon-font-family: 'HoorayToday';

$icon-pinterest-round: "\e90a";
$icon-facebook-round: "\e90b";
$icon-instagram-round: "\e90c";
$icon-mail-round: "\e90d";
$icon-menu: "\e900";
$icon-plus: "\e901";
$icon-arrow-left: "\e902";
$icon-arrow-right: "\e903";
$icon-close: "\e904";
$icon-search: "\e905";
$icon-facebook: "\e906";
$icon-instagram: "\e907";
$icon-mail: "\e908";
$icon-pinterest: "\e909";

@font-face {
  font-family: $icomoon-font-family;
  src:  url('#{$icomoon-font-path}/HoorayToday.eot?6siqnh');
  src:  url('#{$icomoon-font-path}/HoorayToday.eot?6siqnh#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/HoorayToday.ttf?6siqnh') format('truetype'),
    url('#{$icomoon-font-path}/HoorayToday.woff?6siqnh') format('woff'),
    url('#{$icomoon-font-path}/HoorayToday.svg?6siqnh#HoorayToday') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: none;
  font-size: rem-calc(16);
  font-style: normal;
  font-family: $icomoon-font-family !important;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: $line-height-base;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  &-pinterest-round {
    &:before {
      content: $icon-pinterest-round;
    }
  }
  &-facebook-round {
    &:before {
      content: $icon-facebook-round;
    }
  }
  &-instagram-round {
    &:before {
      content: $icon-instagram-round;
    }
  }
  &-mail-round {
    &:before {
      content: $icon-mail-round;
    }
  }
  &-menu {
    &:before {
      content: $icon-menu;
    }
  }
  &-plus {
    &:before {
      content: $icon-plus;
    }
  }
  &-arrow-left {
    &:before {
      content: $icon-arrow-left;
    }
  }
  &-arrow-right {
    &:before {
      content: $icon-arrow-right;
    }
  }
  &-close {
    &:before {
      content: $icon-close;
    }
  }
  &-search {
    &:before {
      content: $icon-search;
    }
  }
  &-facebook {
    &:before {
      content: $icon-facebook;
    }
  }
  &-instagram {
    &:before {
      content: $icon-instagram;
    }
  }
  &-mail {
    &:before {
      content: $icon-mail;
    }
  }
  &-pinterest {
    &:before {
      content: $icon-pinterest;
    }
  }
}

