.page,
.blog,
.search {
  .tac {
    margin-bottom: rem-calc(50);
  }

  h1 {
    color: $red;
    margin: rem-calc(70) 0 rem-calc(35) 0;
    font-size: rem-calc(30);
    text-transform: uppercase;
  }

  h4 {
    margin: rem-calc(20) 0 rem-calc(25) 0;
    font-size: rem-calc(15);
  }

  p {
    font-size: rem-calc(14);
    line-height: 1.5;
  }
}

.single {
  > .wrap {
    display: flex;
    justify-content: space-around;

    @include media('>sm') {
      justify-content: space-between;
    }

    &:before,
    &:after {
      content: none;
    }
  }
  > .wrap > .container {
    margin: 0;

    &:before,
    &:after {
      content: none;
    }

    .tac {
      margin-bottom: rem-calc(50);

      h1 {
        margin: rem-calc(20) 0 rem-calc(5) 0;
        font-size: rem-calc($font-size-large);
        text-transform: uppercase;
      }

      span {
        margin: 0 0 rem-calc(20) 0;
        display: block;
        font-size: rem-calc($font-size-base);
        font-weight: normal;

        &.shariff-icon,
        &.shariff-text {
          display: inline;
          margin: 0;
        }
      }

      p {
        font-size: rem-calc($font-size-base);
        line-height: 1.2;
      }
    }
  }
  .instagram-sidebar {
    display: none;
    margin: rem-calc(100) 0;
    max-width: rem-calc(320);

    @include media('>sm') {
      display: block;
    }

    h4 {
      color: $red;
      margin: 0 0 rem-calc(20);
      text-align: center;
      text-transform: uppercase;
    }
    #sb_instagram {
      position: relative;

      #sbi_images {
        .sbi_item {
          margin-bottom: rem-calc(20) !important;
        }
      }

      #sbi_load {
        left: 0;
        right: 0;
        bottom: -30px;

        .sbi_follow_btn {
          a {
            width: 100%;
            color: $white;
            display: block;
            border-radius: 0 !important;
            background-color: $red !important;
          }
        }
      }
    }
  }
}

// Style for contact page (en and de)
.page {
  > .container {
    &.page-id-163,
    &.page-id-161,
    &.page-id-255,
    &.page-id-257 {
      max-width: rem-calc(690);

      hr {
        border: 0;
        margin: rem-calc(70) 0;
        border-bottom: 1px solid $black;
      }
    }
  }
}

.single {
  > .wrap {
    display: flex;
    justify-content: space-around;

    @include media('>sm') {
      justify-content: space-between;
    }

    &:before,
    &:after {
      content: none;
    }
  }
  > .wrap > .container {
    margin: 0;
    max-width: rem-calc(690);

    &:before,
    &:after {
      content: none;
    }

    hr {
      border: 0;
      margin: rem-calc(70) 0;
      border-bottom: 1px solid $black;
    }
  }
}

.archive {
  .community-text {
    @extend .tac;

    float: none;
    margin: 0 auto;
    max-width: rem-calc(780);

    h3 {
      font-size: rem-calc($font-size-large);
      text-transform: uppercase;
      margin: rem-calc(30) 0 rem-calc(5);
    }

    p {
      font-size: rem-calc($font-size-base);
      max-width: rem-calc(510);
      line-height: 1.3;
      margin: 0 auto rem-calc(25);
    }

    .btn-modal {
      margin: 0 auto rem-calc(35) !important;
      display: block !important;
      font-size: rem-calc($font-size-small);
      font-weight: bold !important;
    }
  }
}
