//==============================================
// Module/nav
//==============================================

// Main header navigation
.m-header-nav {
  @include reset-list;
  width: 100%;
  position: absolute;
  text-align: center;
  background-color: $white;

  @include media('<sm') {
    height: 100vh;
    display: none;

    &::before,
    &::after {
      top: 0;
      width: 100%;
      bottom: 0;
      content: '';
      position: absolute;
      background-color: inherit;
    }

    &::before {
      left: -100%;
    }
    &::after {
      right: -100%;
    }
  }

  @include z(nav);
  @include media('>sm') {
    position: static;
  }

  li {
    display: block;
    border-bottom: -border($color-primary, $b-s-base);

    @include media('>sm') {
      display: inline-block;
      border-bottom: 0;

      &.menu-close {
        display: none;
      }
    }

    // Close menu button
    &.menu-close {
      text-align: right;
      border-bottom: 0;
      padding: ceil($padding/2) 0;

      .m-hamburger-close {
        color: #bebfc1;
        display: inline-block;
        padding: 0;
      }
    }

    a {
      padding: ceil($padding/2);
      color: $color-primary;
      display: block;
      font-size: rem-calc($font-size-nav-header);
      text-transform: uppercase;

      @include media('>sm') {
        padding: ceil($padding/1.5) ceil($padding/1.5) ceil($padding*1.3);
        font-size: rem-calc($font-size-nav-header-desktop);
      }
    }

    // Links States
    &.current_page_item,
    &:hover {
      a {
        color: $color-accent;
      }
    }

    &.current_page_item {
      a {
        font-weight: bold;
      }
    }
  }
}
