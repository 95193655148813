//==============================================
// Module/helpres
//==============================================

.br {
	margin-bottom: $margin;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}
