//==============================================
// Module/event
//==============================================

.m-event {
  @extend .row;
  margin-bottom: rem-calc(20);

  > div {
    display: grid;
    padding: 0 rem-calc(15) rem-calc(15);
    grid-gap: rem-calc(15);
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(1, 1fr);

    @include media('>sm') {
      padding: 0 0 rem-calc(15);
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-item {
    display: block;

    &:hover {
      .inner {
        border-color: $color-accent;
      }
    }

    .inner {
      height: 100%;
      background-color: $white;
      border: -border($white, $b-s-big);
      transition: all $timeline-base;

      img {
        display: block;
        width: 100%;
      }

      .caption {
        padding: $padding*2 $padding;
        padding-left: rem-calc(112);
        position: relative;

        .datetime {
          left: 0;
          position: absolute;
          width: rem-calc(85);

          .date {
            color: $grey-darken;
            display: block;
            font-size: rem-calc(85);
            text-align: center;
            font-weight: bold;
            line-height: 0.75;
          }

          .month {
            color: $color-accent;
            font-size: rem-calc($font-size-small + 4);
            font-weight: bold;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            z-index: 1;
          }
        }

        .title {
          color: $black;
          margin: rem-calc(7) 0 rem-calc(5) 0;
          font-size: rem-calc($font-size-base);
          text-transform: uppercase;
        }

        .desc {
          font-size: 0.9rem;
          word-wrap: break-word;
          line-height: 1;
          margin-bottom: 0;
        }

        .link {
          font-size: rem-calc($font-size-small);
        }
      }
    }
  }
}
