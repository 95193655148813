//==============================================
// Module/metaslider
//==============================================

.metaslider {
  text-align: left;

  @include media('<sm') {
    margin: 0 rem-calc(-15);
  }

  .flexslider {
    overflow: hidden;
    border-radius: 0;

    .flex-direction-nav {
      .flex-prev,
      .flex-next {
        width: rem-calc(50);
        height: rem-calc(50);
        margin-top: rem-calc(-28);
        background: $white;
        text-indent: 0;
        border-radius: rem-calc(50);

        &::after {
          color: $black;
          display: block;
          font-size: rem-calc(24);
          font-family: 'HoorayToday';
          line-height: 50px;
        }
      }

      .flex-prev {
        text-align: right;
        padding-right: rem-calc(2);

        &::after {
          content: $icon-arrow-left;
        }
      }

      .flex-next {
        text-align: left;
        padding-left: rem-calc(2);

        &::after {
          content: $icon-arrow-right;
        }
      }
    }

    &:hover {
      .flex-direction-nav {
        .flex-prev {
          left: rem-calc(-25);
        }
        .flex-next {
          right: rem-calc(-25);
        }
      }
    }

    ul li img {
      @include media('<sm') {
        width: auto !important;
        height: auto !important;
        max-height: rem-calc(250) !important;
      }
    }
  }

  .caption-wrap {
    opacity: 1 !important;
    background: transparent !important;

    .caption {
      padding: rem-calc(25) rem-calc(50);

      h1 {
        color: #313131;
        margin: 0;
        font-size: rem-calc(48);
        line-height: 1;
        text-transform: uppercase;
      }

      h2 {
        color: $black;
        margin: 0 0 rem-calc(10);
        font-size: rem-calc(32);
        font-weight: normal;
        line-height: 1;
      }

      .btn {
        width: auto;
        color: white;
        border: 0;
        padding: rem-calc(10);
        font-size: rem-calc(13);
        border-radius: rem-calc(5);
        text-transform: uppercase;
        background-color: #313131;
      }
    }
  }
}
