.haet-cleverreach {
  &-form {
    input,
    textarea {
      width: 100%;
      color: $black;
      border: 0;
      resize: none;
      display: block;
      outline: 0;
      padding: rem-calc(10) rem-calc(20);
      background-color: $grey;
    }

    button[type="submit"] {
      @extend .btn;

      float: left;
      padding: rem-calc(12) rem-calc(45);
      max-width: rem-calc(150);
      font-size: rem-calc($font-size-small);
    }
  }
}
