.m-content-box {
  width: 100%;
  margin: $margin;
  display: block;
  padding: $padding/2 $padding/2 $padding;
  text-align: center;
  background-color: $white;

  @include media('>sm') {
    margin: 0 $margin;
    min-height: 365px;
  }

  img {
    margin: 0 0 $margin 0;
  }

  h3,
  h4 {
    color: $color-text-main;
    margin: 0;
    text-transform: uppercase;
  }

  h3 {
    font-size: rem-calc(26);
  }

  h4 {
    font-size: rem-calc(22);
    font-weight: normal;
  }

  p {
    margin: $margin/2 0;
    font-size: rem-calc(16);
    line-height: rem-calc(18);
    font-weight: normal;
  }

  span {
    color: $color-text-main;
    font-size: rem-calc(14);
    line-height: rem-calc(16);
    font-weight: bold;
  }

  &--wrapper {
    display: flex;
    align-items: center;
    padding-top: $padding*2;
    margin-left: -$margin;
    margin-right: -$margin;
    padding-bottom: $padding*2;
    flex-direction: column;
    justify-content: center;

    @include media('>sm') {
      flex-direction: row;
    }
  }
}
