.m-category-posts {
  width: 100%;
  margin: 0 0 rem-calc(50) 0;
  padding: 0 rem-calc(15);
  display: grid;
  grid-gap: rem-calc(15);
  grid-template-columns: repeat(1, 1fr);

  @include media('>sm') {
    padding: 0;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
  }

  li {
    border: rem-calc(2) solid $grey;
    display: block;

    &:first-child {
      @include media('>sm') {
        grid-row: 1;
        grid-column: 1/3;
      }
    }

    img {
      display: block;
    }

    > div {
      position: relative;

      .name {
        top: 50%;
        left: 50%;
        color: $white;
        z-index: 1;
        padding: rem-calc(12) rem-calc(15);
        position: absolute;
        transform: rotate(0) translate(-50%, -25%);
        font-size: rem-calc($font-size-base);
        background-color: $red;

        @include media('>sm') {
          top: 50%;
          left: 0;
          transform: rotate(-90deg) translate(20%, -120%);
        }
      }
    }

    &.m-category-posts-post {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      a {
        display: block;
        text-align: center;
      }

      div {
        height: 100%;
        padding: rem-calc(10) 0 0 0;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        h3 {
          margin: 0;
          padding: 0;
          font-size: rem-calc($font-size-large);
          font-weight: normal;
        }

        small {
          color: $red;
          margin: 0 0 rem-calc(10) 0;
          padding: 0;
          font-size: rem-calc($font-size-base);
        }

        a {
          color: $black;
          margin: rem-calc(5) 0 rem-calc(10) 0;
          padding: 0;
          font-weight: bold;
        }
      }
    }
  }
}
