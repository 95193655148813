//==============================================
// Layout/header
//==============================================

.l-header {
  .nav {
    position: relative;

    @include media('>sm') {
      border-bottom: -border($color-primary);
    }
  }

  .search {
    @include clearfix;
    padding: ceil($padding/1.5) 0;

    .m-search {
      float: right;
    }
  }
}

