.m-related-posts,
.m-products-list,
.m-related-products {
  position: relative;
  padding-top: $padding*2;
  padding-bottom: $padding*2;

  h3 {
    color: $red;
    margin: $margin*2 0;
    display: flex;
    position: relative;
    text-align: center;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;

    .title {
      width: 160%;
      display: block;
    }

    .line {
      width: 100%;
      height: 1px;
      border-top: rem-calc(1) solid $red;
    }
  }

  &--item {
    border: $padding/4 solid $grey;
    margin: $margin/2;
    padding: 0 0 $padding/2 0;
    display: grid;
    text-align: center;
    background-color: $white;
  }

  &--name {
    padding: 0 $padding/2;
  }

  h4 {
    margin: 0;

    a {
      color: $color-text-main;
      font-size: rem-calc(18);
      text-transform: capitalize;
    }
  }

  hr {
    width: rem-calc(30);
    height: 0;
    display: block;
    border-top: rem-calc(1) solid $color-text-main;
    text-align: center;
  }

  span {
    &.has-special-price {
      text-decoration: line-through;
    }
    &.special-price {
      color: $red;
      text-decoration: none;
    }
  }

  &--wrapper {
    display: grid;
    grid-template: "a"
                   "a"
                   "a"
                   "a";
    margin-left: -$margin/2;
    margin-right: -$margin/2;
    grid-auto-columns: 1fr;

    @include media('>sm') {
      grid-template: "a a a a";
    }
  }
}

.m-related-products {
  &--wrapper {
    display: grid;
    grid-template: "a"
                   "a"
                   "a";
    margin-left: -$margin/2;
    margin-right: -$margin/2;
    grid-auto-columns: 1fr;

    @include media('>sm') {
      grid-template: "a a a";
    }
  }
}

.m-related-posts {
  &--wrapper {
    display: grid;
    grid-template: "a"
                   "a";
    margin-left: -$margin/2;
    margin-right: -$margin/2;
    grid-auto-columns: 1fr;

    @include media('>sm') {
      grid-template: "a a";
    }
  }

  &--item {
    > a {
      margin: 0 0 rem-calc(10) 0;
    }
    > h4 {
      margin: rem-calc(10) 0 0 0;
    }
  }
}
