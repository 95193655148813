//==============================================
// Utilites/Typography
//==============================================

// Scaling font size from h4 to h1
$scale: 1.25 !default;

// $base-font-size
$font-size-base: 18 !default;
$font-size-medium: 20 !default;
$font-size-large: 22 !default;
$font-size-small: 12 !default;

// modules font size
$font-size-nav-header: $font-size-medium;
$font-size-nav-header-desktop: $font-size-base;

// $line-height
$line-height-base: 1 !default;
$line-height-medium: 1.6 !default;
$line-height-large: 1.8 !default;
$line-height-small: 1.5 !default;

// Round a number down
$line-height-count: floor(($font-size-base * $line-height-base)) !default;

// font family
$font-family-fallback: sans-seri, Arial;
$font-family-default: 'Source Sans Pro', $font-family-fallback;
$font-family-base: $font-family-default;
$font-family-monospace: 'Inconsolata', 'Menlo', 'Consolas', 'Bitstream Vera Sans Mono', 'Courier', monospace;
