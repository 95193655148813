// Color Palette
// Based on the official Sass styleguide

// ===================================================================
// Descriptive colors
// ===================================================================
$black: #24272a !default;
$black-light: lighten(#000, 25%) !default;
$white: #fff !default;
$white-darken: darken($white, 35%) !default;
$red: #e3746e !default;
$grey: #f2f2f2 !default;
$grey-darken: darken($grey, 7%) !default;

// ===================================================================
// Theme colors
// ===================================================================
$color-primary: $black !default;
$color-primary-light: lighten($color-primary, 25%) !default;
$color-accent: $red !default;
$color-accent-light: lighten($color-accent, 25%) !default;
$color-accent-darken: darken($color-accent, 5%) !default;
$color-shadow: rgba($black, .125);

// ===================================================================
// Main elements colors
// ===================================================================
$text-link: $color-accent !default;
$color-text-main: $black-light !default;
$color-headers: $black-light !default;

// links
$color-link: $color-accent !default;
$color-link-hover: $color-accent-darken !default;
$color-link-visited: $color-accent !default;
$color-link-bg-hover: $color-accent !default;

// background
$color-background: $white !default;
