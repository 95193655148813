//==============================================
// Module/your custom reset
//==============================================

address {
	font-style: normal;
}

figure {
	margin: 0;
}