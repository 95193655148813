//==============================================
// Module/filter
//==============================================

.m-filters {
  @extend .row;
  padding: rem-calc($padding * 2) rem-calc(15);
  margin-top: rem-calc(-$margin);

  @include media('>sm') {
    padding: rem-calc($padding * 2) 0 0 0;
  }

  &-item {
    margin: 0 rem-calc($margin) rem-calc($margin) 0;
    display: inline-block;
  }
}
