#sbi_load {
  padding: 0 !important;
  position: absolute !important;

  .sbi_follow_btn {
    margin: 0 auto !important;
    display: block !important;
    max-width: 970px !important;

    a {
      float: left !important;
      margin: 0 !important;
      font-size: rem-calc(16);
      border-radius: 0 5px 5px 0 !important;
      text-transform: uppercase !important;
      background-color: $black !important;

      svg {
        display: none;
      }
    }
  }
}
