//==============================================
// Module/search
//==============================================

.m-search {
  width: rem-calc(22);
  padding: ceil($padding/5) ceil($padding/2);
  overflow: hidden;
  position: relative;
  text-align: right;
  transition: all $timeline-fast;
  border-radius: $border-radius-medium;

  &.is-open {
    width: rem-calc(180);
    background-color: $grey;

    .btn-search {
      background-color: transparent;
    }

    .input-search {
      text-indent: 0;
    }
  }

  .input-search {
    width: 100%;
    border: 0;
    outline: none !important;
    display: block;
    font-size: rem-calc($font-size-small);
    background: transparent;
    text-indent: -999px;
    padding-right: rem-calc(15);
  }

  .btn-search {
    @extend .m-hamburger;
    @extend .icon-search;
    top: 0;
    right: rem-calc(-5);
    cursor: pointer;
    outline: none !important;
    position: absolute;
    font-size: rem-calc($font-size-base) !important;

    @include media('>sm') {
      right: rem-calc(-2);
    }
  }
}
