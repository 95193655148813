//==============================================
// Layout/sections
//==============================================

.l-section {
  padding: $padding 0;

  &--grey {
    @extend .l-section;
    background-color: $grey;
  }
  &--grey__darken {
    @extend .l-section;
    background-color: $grey-darken;
  }
}

