//==============================================
// Layout/search
//==============================================

.l-search-hamburger {
  @include clearfix;
  padding: ceil($padding/4) 0;
  border-top: -border($grey, $b-s-base);
  border-bottom: -border($grey, $b-s-base);

  @include media(">sm"){
    display: none;
  }

  .m-hamburger {
    float: left;
  }

  .m-search {
    float: right;
  }
}
