.wpcf7 {
  p {
    float: left;
    width: 100%;
  }

  h3 {
    margin: 0 0 rem-calc(20);
    font-size: rem-calc($font-size-base);
    font-weight: normal;
  }

  input,
  textarea {
    width: 100%;
    color: $black;
    border: 0;
    resize: none;
    display: block;
    outline: 0;
    padding: rem-calc(10) rem-calc(20);
    background-color: $grey;

    &[type="file"] {
      display: none;
    }
  }

  textarea {
    height: rem-calc(300);
  }

  input[type="submit"] {
    @extend .btn;

    float: left;
    padding: rem-calc(12) rem-calc(35);
    max-width: rem-calc(150);
    font-size: rem-calc($font-size-small);
  }

  .ajax-loader {
    float: right;
  }

  .wpcf7-response-output {
    float: left;
    width: 100%;
  }
}
