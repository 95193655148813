//==============================================
// Module/lang
//==============================================

// Main header navigation
.m-lang {
  @include reset-list;
  display: none;
  font-size: rem-calc($font-size-nav-header-desktop/1.25);
  margin-bottom: ceil($margin/2);
  margin-top: -$margin;
  padding-left: 0;
  text-transform: uppercase;

  .wpml-ls-sidebars-header_language_selector {
    border: 0;
    padding: 0;

    ul {
      display: flex;
      padding: 0;

      li {
        a {
          padding: 0;
        }

        &:first-child {
          position: relative;
          padding-right: 15px;

          &:after {
            top: 0;
            right: rem-calc(5);
            color: $red;
            content: '/';
            display: block;
            position: absolute;
          }
        }
      }

      .wpml-ls-current-language {
        font-weight: bold;
      }
    }
  }

  @include media('>sm') {
    border-bottom: 0;
    font-size: rem-calc($font-size-nav-header-desktop);
    margin: 0;
    padding: 0;
    position: absolute;

    .wpml-ls-sidebars-header_language_selector {
      ul {
        padding: 8px 0 20px;
      }
    }
  }

  ul {
    @include reset-list;

    li {
      display: inline-block;

      &.current-lang {
        font-weight: bold;
      }
    }
  }
}
