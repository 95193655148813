.m-comments {
  width: 100%;
  display: block;

  > h3 {
    margin: 0;
    padding: rem-calc(15) 0;
    font-size: rem-calc($font-size-base);
    border-top: rem-calc(1) solid $gray;
    font-weight: normal;
    border-bottom: rem-calc(1) solid $gray;
    text-transform: uppercase;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 rem-calc(20) 0;
      padding: 0 0 rem-calc(20) 0;
      border-bottom: rem-calc(1) solid $gray;

      h4 {
        color: $red;
        margin: rem-calc(20) 0 rem-calc(5) 0;
        text-transform: uppercase;

        small {
          font-weight: normal;
        }
      }

      p {
        margin: 0;
        font-size: rem-calc($font-size-base);
        line-height: 1.2;
      }
    }
  }

  .new-comment {
    > h3 {
      margin: rem-calc(50) 0 rem-calc(30) 0;
      padding: 0;
      font-size: rem-calc($font-size-base);
      text-align: center;
      font-weight: normal;
      text-transform: uppercase;
    }

    form {
      width: 100%;
      float: left;
      margin: 0 0 rem-calc(50);
      padding: 0 0 rem-calc(30) 0;
      display: block;
      border-bottom: rem-calc(1) solid $gray;

      input,
      textarea {
        width: 100%;
        color: $black;
        border: 0;
        resize: none;
        display: block;
        outline: 0;
        padding: rem-calc(10) rem-calc(20);
        background-color: $grey;

        &[type="file"] {
          display: none;
        }
      }

      textarea {
        height: rem-calc(150);
      }

      input[type="submit"] {
        @extend .btn;

        float: left;
        padding: rem-calc(12) rem-calc(5);
        max-width: rem-calc(170);
        font-size: rem-calc($font-size-small);
      }
    }
  }
}
