.m-modal-event {
  .modal-header {
    border: 0;
  }

  .modal-content {
    border: 1px solid $black;
    box-shadow: none;
    border-radius: 0;
  }

  .modal-title {
    color: $red;
    margin: rem-calc(25) 0 rem-calc(10) 0;
    font-size: rem-calc($font-size-large);
    text-align: center;
  }

  .modal-body {
    padding: rem-calc(0) rem-calc(45) rem-calc(35);
  }

  .btn.close {
    top: 0;
    right: 0;
    color: $white-darken;
    padding: rem-calc(10);
    position: absolute;
    background-color: transparent;
  }

  input,
  textarea {
    width: 100%;
    color: $black;
    border: 0;
    resize: none;
    display: block;
    outline: 0;
    padding: rem-calc(10) rem-calc(20);
    background-color: $grey;

    &[type="file"] {
      display: none;
    }
  }

  textarea {
    height: rem-calc(300);
  }

  label[for="image"] {
    width: 100%;
    border: rem-calc(1) solid $white-darken;
    display: flex;
    padding: rem-calc(8) rem-calc(5);
    line-height: 1;

    i {
      color: $red;
    }
  }

  .info {
    font-size: rem-calc($font-size-small);
  }

  .btn-send {
    padding: rem-calc(12) rem-calc(45);
    font-size: rem-calc($font-size-small);
  }
}
