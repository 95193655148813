html {
  height: 100%;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;

  &:before,
  &:after {
    @extend *;
  }
}

body {
  color: $color-text-main;
  position: relative;
  font-size: rem-calc($font-size-base);
  line-height: $line-height-base;
  font-family: $font-family-base;
  padding-bottom: 0;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @include media('>sm') {
    padding-bottom: rem-calc(250);
  }
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  margin: 0;
}

h1, .h1 {
  font-size: rem-calc(26);

  @include media('>sm') {
    font-size: rem-calc(29);
  }

  @include media('>md') {
    font-size: rem-calc(37);
  }
}

h2, .h2 {
  font-size: rem-calc(22);
  font-weight: bold;

  @include media('>sm') {
    font-size: rem-calc(26);
  }

  @include media('>md') {
    font-size: rem-calc(29);
  }
}

h3, .h3 {
  font-size: rem-calc(22);

  @include media('>sm') {
    font-size: rem-calc(22);
  }

  @include media('>md') {
    font-size: rem-calc(24);
  }
}

a {
  color: $color-link;
  background: transparent;
  transition: color .2s;
  text-decoration: $link-hover-decoration;

  @include hover {
    color: $color-link-hover;
  }
}

p {
  color: transparentize($color-text-main, .1);
  margin: 0;
  font-weight: 400;
  line-height: $line-height-small;
  margin-bottom: $margin;
}

small,
.small {
  color: $color-text-main;
  font-size: $font-size-small;
  line-height: $line-height-base;
}

b { font-weight: 600; }
strong { font-weight: 800; }

img {
  height: auto;
  max-width: 100%;
}
