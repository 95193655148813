.m-cookies {
  width: 100%;
  bottom: 0;
  display: none;
  position: fixed;
  background: $white;
  border-top: -border($red, $b-s-medium);

  @include z(overlay);

  .container {
    .row {
      display: flex;
      align-items: center;
    }
  }

  a, p {
    color: $black;
    margin: 0;

    @include media("<md") {
      font-size: rem-calc($font-size-small);
    }
  }

  p {
    padding: 0;
    line-height: 1;
  }

  a {
    text-decoration: underline;
  }

  button {
    margin: rem-calc(10) auto;
    padding: rem-calc(8) rem-calc(15);
    display: block;
    font-size: rem-calc($font-size-base);
    background-color: $red;
  }
}
