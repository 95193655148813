.blog, .page-home {
  .tac {
    h3 {
      font-size: rem-calc($font-size-large);
      text-transform: uppercase;
      margin: rem-calc(30) 0 rem-calc(5);
    }

    p {
      font-size: 1rem;
      max-width: rem-calc(510);
      line-height: 1.3;
      margin: 0 auto rem-calc(25);
    }
  }

  .btn-modal {
    margin: 0 auto rem-calc(35) !important;
    display: block !important;
    font-size: rem-calc($font-size-small);
    font-weight: bold !important;
  }
}
.page-home {
  .alm-btn-wrap {
    display: none;
  }
  .tac {
    p {
      font-size: rem-calc(16);
    }
  }
}
