//==============================================
// Module/buttons
//==============================================

.btn {
  @include m-btn-size;
  background-color: $btn-default-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: $b-s-base solid $btn-default-border;
  color: $btn-default-color;
  cursor: pointer;
  display: inline-block;
  font-size: rem-calc($font-size-base);
  font-weight: $btn-font-weight;
  line-height: $line-height-base;
  margin-bottom: 0;
  outline: none;
  text-align: center;
  touch-action: manipulation;
  transition: all $timeline-base !important;
  user-select:none;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: $padding-base-vertical $padding-base-horizontal;

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    opacity:.65;
    box-shadow:none;
  }

  @include hover {
    color: $btn-default-color;
  }

  &.full {
    width: 100%;
  }


  // Modificators
  &--white {
    @extend .btn;
    background-color: $white;
    color: $color-accent;
    padding: rem-calc(8) rem-calc(30);
    font-size: rem-calc($font-size-small);

    &:hover,
    &.is-active {
      color: $white;
      background-color: $btn-default-bg;
    }
  }

  &--big {
    @extend .btn;
    font-size: rem-calc($font-size-base);
  }
}
