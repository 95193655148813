//==============================================
// Module/social_icons
//==============================================

.m-social-icons {
  top: rem-calc(160);
  left: 0;
  width: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  display: block;
  position: fixed;
  list-style: none;

  li {
    width: rem-calc(130);
    height: rem-calc(30);
    display: block;
    padding: rem-calc(2);
    transform: translateX(rem-calc(-101));
    transition: all 0.5s ease-in-out;
    margin-bottom: rem-calc(2);
    border-radius: 0 rem-calc(30) rem-calc(30) 0;
    background-color: $red;

    &:first-of-type {
      a {
        .text {
          line-height: rem-calc(13);
        }
      }
    }

    a {
      display: flex;
      align-content: center;
      justify-content: space-between;

      .text {
        width: rem-calc(100);
        color: $white;
        margin: 0;
        font-size: rem-calc($font-size-small);
        line-height: rem-calc(25);
      }

      .icon {
        width: rem-calc(25);
        height: rem-calc(25);
        display: block;
        position: relative;
        border-radius: rem-calc(25);
        background-color: $white;

        i {
          top: rem-calc(-1);
          left: rem-calc(-2);
          position: absolute;
          font-size: rem-calc(28);
          line-height: rem-calc(28);
          border-radius: rem-calc(3280);
          background-color: transparent;
        }
      }

      &:hover {
        color: $red;
      }
    }

    &:hover {
      transform: translateX(0);
    }
  }
}
